import {Component, ElementRef, Renderer2, ViewChild} from '@angular/core';
import {AuthService} from "../../services/auth.service";
import { VehicleStatuses } from '../../../models/enums/vehicle-statuses.enum';
import {RouteInfo} from "../../sidebar.metadata";
import {UserStatuses} from "../../../models/enums/user-statuses.enum";
import {AppComponent} from "../../../app.component";
import {NavigationEnd, NavigationError, NavigationStart, Router} from "@angular/router";

@Component({
  selector: 'app-admin-header',
  templateUrl: './admin-header.component.html',
  styleUrl: './admin-header.component.scss'
})
export class AdminHeaderComponent {
    public lastLoggedInAt: string = 'TODO: Add this to user table and update each time log in.';
    public vehicleMenuIsCollapsed: boolean = true;
    public menuItems: any[] = this.getSidebarLinks(false);
    public toggleClass: string|null = null;

    public constructor(
        public authService: AuthService,
        private elementRef: ElementRef,
        private renderer: Renderer2,
        private router: Router
    )
    {
        this.router.events.subscribe(event => {
           if (event instanceof NavigationStart) {
               // TODO: Show loading indicator
           }

           if (event instanceof NavigationEnd && window.innerWidth < 1025) {
               this.toggleSidebarClass();
           }

           if (event instanceof NavigationError) {
               console.error(event.error);
           }
        });

        //this.preferencesService.privacyMode

        // this.preferencesService.privacyModeChange.subscribe(
        //     value => this.navHeader = this.getSidebarLinks(value),
        // );
    }

    public ngOnInit(): void
    {
        // TODO: Convert to the angular way
        // $('.toggle-icon').click(() => {
        //     if ($('.wrapper').hasClass('toggled')) {
        //         // unpin sidebar when hovered
        //         $('.wrapper').removeClass('toggled');
        //         $('.sidebar-wrapper').unbind('hover');
        //     } else {
        //         $('.wrapper').addClass('toggled');
        //         $('.sidebar-wrapper').hover(() => {
        //             $('.wrapper').addClass('sidebar-hovered');
        //         }, () => {
        //             $('.wrapper').removeClass('sidebar-hovered');
        //         });
        //     }
        // });
    }


    public togglePrivacyMode(value: boolean): void
    {
        // this.preferencesService.privacyMode = value;
    }

    public toggleSidebarClass()
    {
        const wrapper = this.elementRef.nativeElement.parentNode.parentNode;

        if (wrapper.classList.contains('toggled')) {
            this.renderer.removeClass(wrapper, 'toggled');
        } else {
            this.renderer.addClass(wrapper, 'toggled');
        }

        this.toggleClass = this.toggleClass ? null : 'toggled';
    }

    public expandSubMenu(item: RouteInfo, index: number): void
    {
        this.menuItems.forEach((menuItem: RouteInfo) => {
            if (menuItem !== item && menuItem.id !== item.parentId) {
                menuItem.expanded = false;
            }
        });

        if (item.submenu) {
            item.expanded = !item.expanded;
        }
    }

    private getSidebarLinks(privacyMode: boolean): RouteInfo[]
    {
        return [
            {
                id: 1,
                title: 'Dashboard',
                path: 'dashboard',
                icon: 'fa-address-card',
                class: '', badge: '', badgeClass: '', isExternalLink: false,
                active: true
            },
            {
                id: 2,
                title: 'Leads',
                path: 'leads',
                icon: 'fa-user-plus',
                class: '', badge: '', badgeClass: '', isExternalLink: false,
                active: true
            },
            {
                id: 3,
                title: 'Calendar',
                path: 'calendar',
                icon: 'fa-calendar-alt',
                class: '',
                badge: '',
                badgeClass: '',
                isExternalLink: false,
                active: true
            },
            {
                id: 4,
                title: 'Vehicles',
                path: 'vehicle',
                queryParams: {status_id: VehicleStatuses.InStock},
                icon: 'fa-car-side',
                class: 'sub', badge: '', badgeClass: '', isExternalLink: false,
                active: true,
                submenu: [
                    {
                        id: 5,
                        title: 'All Vehicles',
                        path: 'vehicles',
                        queryParams: {status_id: ''},
                        icon: 'fa-arrow-right',
                        class: '',
                        badge: '',
                        badgeClass: '',
                        isExternalLink: false,
                        active: true,
                        parentId: 4
                    },
                    {
                        id: 6,
                        title: 'In Stock',
                        path: 'vehicles',
                        queryParams: {status_id: VehicleStatuses.InStock},
                        icon: 'fa-arrow-right',
                        class: '',
                        badge: '',
                        badgeClass: '',
                        isExternalLink: false,
                        active: true,
                        parentId: 4
                    },
                    {
                        id: 7,
                        title: 'Ex Stock',
                        path: 'vehicles',
                        queryParams: {status_id: VehicleStatuses.ExStock},
                        icon: 'fa-arrow-right',
                        class: '', badge: '', badgeClass: '', isExternalLink: false,
                        active: true,
                        parentId: 4
                    },
                    {
                        id: 8,
                        title: 'SOR Stock',
                        path: 'vehicles',
                        queryParams: {status_id: VehicleStatuses.SaleOrReturn},
                        icon: 'fa-arrow-right',
                        class: '', badge: '', badgeClass: '', isExternalLink: false,
                        active: true,
                        parentId: 4
                    },
                    {
                        id: 9,
                        title: 'Deposit Taken',
                        path: 'vehicles',
                        queryParams: {status_id: VehicleStatuses.DepositTaken},
                        icon: 'fa-arrow-right',
                        class: '', badge: '', badgeClass: '', isExternalLink: false,
                        active: true,
                        parentId: 4
                    },
                    {
                        id: 10,
                        title: 'Awaiting Stock',
                        path: 'vehicles',
                        queryParams: {status_id: VehicleStatuses.AwaitingStock},
                        icon: 'fa-arrow-right',
                        class: '', badge: '', badgeClass: '', isExternalLink: false,
                        active: true,
                        parentId: 4
                    },
                ]
            },
            {
                id: 11,
                title: 'Invoices',
                path: 'invoices',
                icon: 'fa-file-invoice',
                class: '', badge: '', badgeClass: '', isExternalLink: false,
                active: !privacyMode
            },
            {
                id: 12,
                title: 'Reports',
                path: 'reports',
                icon: 'fa-chart-bar',
                class: '', badge: '', badgeClass: '', isExternalLink: false,
                active: !privacyMode
            },
            {
                id: 13,
                title: 'Contacts',
                path: 'contacts',
                icon: 'fa-address-card',
                class: '', badge: '', badgeClass: '', isExternalLink: false,
                active: !privacyMode
            },
            {
                id: 14,
                title: 'Users',
                path: 'users',
                queryParams: {status_id: [UserStatuses.Invited, UserStatuses.Active]},
                icon: 'fa-users',
                class: '', badge: '', badgeClass: '', isExternalLink: false,
                active: !privacyMode
            },
            {
                id: 15,
                title: 'Settings',
                path: 'settings',
                fragment: 'dealer-details',
                icon: 'fa-cogs',
                class: '', badge: '', badgeClass: '', isExternalLink: false,
                active: !privacyMode
            }
        ];
    }
}
