import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {DashboardComponent} from "./dashboard/dashboard.component";
import {AccountIntegrationGuard} from "../core/guards/account-integration.guard";

const routes: Routes = [
    {
        path: '',
        canActivate: [AccountIntegrationGuard],
        component: DashboardComponent,
        data: { title: 'Dashboard' }
    },
    {
        path: 'calendar',
        canActivate: [AccountIntegrationGuard],
        loadChildren: () => import('./calendar/calendar.module').then(m => m.CalendarModule),
    },
    {
        path: 'vehicles',
        canActivate: [AccountIntegrationGuard],
        loadChildren: () => import('./vehicles/vehicles.module').then(m => m.VehiclesModule),
    },
    {
        path: 'invoices',
        canActivate: [AccountIntegrationGuard],
        loadChildren: () => import('./invoices/invoices.module').then(m => m.InvoicesModule)
    },
    {
        path: 'reports',
        canActivate: [AccountIntegrationGuard],
        loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule)
    },
    {
        path: 'contacts',
        canActivate: [AccountIntegrationGuard],
        loadChildren: () => import('./contacts/contacts.module').then(m => m.ContactsModule)
    },
    {
        path: 'users',
        canActivate: [AccountIntegrationGuard],
        loadChildren: () => import('./users/users.module').then(m => m.UsersModule)
    },
    {
        path: 'settings',
        loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule)
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AdminRoutingModule {
}
